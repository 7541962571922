import React from 'react';
import _forEach from 'lodash/forEach';
import _range from 'lodash/range';
import _toInteger from 'lodash/toInteger';
import _map from 'lodash/map';
import { Link } from 'react-router-dom';
import { defineMessages, injectIntl } from '@kyruus/intl';
import { makeInternational } from '../utils/intl-components';

const messages = defineMessages({
  gotoPreviousPage: {
    id: 'aria.label.gotoPreviousPage',
    description: 'Accessibility label for navigating to previous page',
    defaultMessage: 'Go to previous page'
  },
  gotoNextPage: {
    id: 'aria.label.gotoNextPage',
    description: 'Accessibility label for navigating to next page',
    defaultMessage: 'Go to next page'
  },
  gotoPage: {
    id: 'aria.label.gotopage',
    description: 'Accessibility label for navigating to a page',
    defaultMessage: 'Go to page {page}'
  },
  paginationControls: {
    id: 'aria.label.paginationControls',
    description: 'Accessibility label navigation container',
    defaultMessage: 'Results Page'
  }
});

const IntlLink = makeInternational(Link);

function pagesToShow(currentPage, totalPages) {
  const pages = [
    { text: '«', value: currentPage - 1 },
    { text: 1, value: 1 }
  ];
  const firstNumber = Math.max(currentPage - 2, 2);
  const lastNumber = Math.min(currentPage + 2, totalPages);
  if (firstNumber > 2) {
    pages.push({ text: '...', value: -1 });
  }
  _forEach(_range(firstNumber, lastNumber + 1), (number) => {
    pages.push({ text: number, value: number });
  });
  if (lastNumber + 1 < totalPages) {
    pages.push({ text: '...', value: -2 });
  }
  if (lastNumber < totalPages) {
    pages.push({ text: totalPages, value: totalPages });
  }
  pages.push({ text: '»', value: currentPage + 1 });
  return pages;
}

function Pagination({
  searchSummary,
  totalPages,
  getUpdatedSearch,
  log,
  intl
}) {
  const currentPage = _toInteger(searchSummary.page) || 1;
  const pageLinks = _map(pagesToShow(currentPage, totalPages), (page) => {
    let linkAriaMessage = messages.gotoPage;
    if (page.text === '«') {
      linkAriaMessage = messages.gotoPreviousPage;
    } else if (page.text === '»') {
      linkAriaMessage = messages.gotoNextPage;
    }
    const key = `${page.text}-${page.value}`;
    let className, paginationComponent;
    let ariaProps = {};
    if (page.value < 1 || page.value > totalPages) {
      className = 'disabled';
      paginationComponent = <span>{page.text}</span>;
      ariaProps = { 'aria-hidden': true };
    } else if (page.value == currentPage) {
      className = 'active';
      paginationComponent = (
        <span name={page.value} aria-current="page">
          {page.text}
        </span>
      );
    } else {
      const logPagination = () =>
        log('user_action.search_results.page_results', {
          page_num: page.value,
          button_clicked: page.text,
          total_pages: totalPages
        });
      paginationComponent = (
        <IntlLink
          data-testid={`LinkToPage${page.value}`}
          to={getUpdatedSearch([
            { action: 'append', key: 'page', value: page.value }
          ])}
          name={page.value}
          onClick={logPagination}
          intlProps={{
            'aria-label': {
              descriptor: linkAriaMessage,
              values: { page: page.value }
            }
          }}
        >
          {page.text}
        </IntlLink>
      );
    }
    return (
      <li key={key} {...ariaProps} className={className}>
        {paginationComponent}
      </li>
    );
  });

  return (
    <nav
      className="pagination-col"
      data-current-page={searchSummary.page}
      data-total-pages={totalPages}
      data-view="paging"
      aria-label={intl.formatMessage(messages.paginationControls)}
    >
      <ul className="pagination no-bullets">{pageLinks}</ul>
    </nav>
  );
}

export { pagesToShow };
export default injectIntl(Pagination);
