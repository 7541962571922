import React, { useState, useEffect } from 'react';
import { defineMessages, FormattedMessage } from '@kyruus/intl';
import { withRouter } from 'react-router-dom';

import LocationInput from './location-input';
import { IntlOption } from '../../utils/intl-components';

import useSuggestions from '../../hooks/useSuggestions';

import { getResolvedSearchLocation } from '../../utils/location';
import { logSentryError } from '../../utils/logSentryError';
import { isModuleEnabled, MODULES } from 'Common/config';
import { LocationFacetContainer } from './styles';

const DISTANCE_ANY = 'Any';

const messages = defineMessages({
  within: {
    id: 'facet.location.within',
    description: "Title text for a relative distance, e.g. 'Within 5 miles of'",
    defaultMessage: 'Within'
  },
  within_aria_label: {
    id: 'facet.location.within.aria_label',
    description: 'Aria label for the within this distance select input',
    defaultMessage: 'Select within distance'
  },
  anymiles: {
    id: 'facet.location.anymiles',
    description: "Text to display when distance in miles is set to 'Any'",
    defaultMessage: 'Any miles'
  },
  numericalmiles: {
    id: 'facet.location.numericalmiles',
    description:
      'Text to display when distance in miles is set to a numerical value',
    defaultMessage: `{distance, plural,
      one {# mile}
      other {# miles}
    }`
  },
  apply: {
    id: 'facet.location.apply',
    description: 'Button text to apply changes to the distance facet',
    defaultMessage: 'Apply'
  },
  placeholder: {
    id: 'facet.location.placeholder',
    description: 'Placeholder text for the location facet',
    defaultMessage: 'City, State or ZIP'
  }
});

function BaseLocationFacet(props) {
  const {
    config,
    customerConfig,
    searchSummary,
    searchableLocation,
    history,
    getUpdatedSearch
  } = props;

  const defaultDistance = searchSummary.location
    ? DISTANCE_ANY
    : config.default_distance;

  const [searchParams, setSearchParams] = useState({
    // searchSummary.distance can be 0 which is a valid radius (any miles)
    distance:
      searchSummary.distance == null ? defaultDistance : searchSummary.distance,
    searchLocation: searchSummary.location || '',
    searchDisplayLocation:
      searchSummary.display_location || searchSummary.location || ''
  });

  // term used to fetch suggestions for
  const [inputSuggestionsTerm, setInputSuggestionsTerm] = useState(undefined);

  // auto-suggest suggestions
  const { suggestions } = useSuggestions({ term: inputSuggestionsTerm });

  useEffect(() => {
    setSearchParams({
      searchLocation: searchSummary.location || '',
      searchDisplayLocation:
        searchSummary.display_location || searchSummary.location || '',
      distance:
        searchSummary.distance == null
          ? defaultDistance
          : searchSummary.distance
    });
  }, [
    searchSummary.location,
    searchSummary.display_location,
    searchSummary.distance,
    defaultDistance
  ]);

  /**
   * Modifies the route history with the current filters and applies them to update the search results
   */
  async function applyFilter({
    searchLocation,
    searchDisplayLocation,
    distance
  }) {
    if (!searchLocation) {
      return;
    }

    let resolvedSearchLocation = searchLocation;
    try {
      resolvedSearchLocation = await getResolvedSearchLocation(searchLocation);
    } catch (e) {
      // could not get coords for location,
      // we'll pass location as we got it and hope SS can deal with it
      logSentryError(e);
    }

    const modifications = [
      {
        action: 'append',
        key: 'location',
        value: resolvedSearchLocation
      },
      {
        action: 'append',
        key: 'display_location',
        value: searchDisplayLocation
      }
    ];

    if (distance === DISTANCE_ANY) {
      modifications.push({ action: 'append', key: 'distance', value: 0 });
    } else {
      modifications.push({
        action: 'append',
        key: 'distance',
        value: distance
      });
    }

    const updatedSearch = getUpdatedSearch(modifications);
    history.push(updatedSearch);
  }

  function handleUseCurrentLocation(location, displayLocation, distance) {
    const params = {
      searchLocation: location,
      searchDisplayLocation: displayLocation,
      distance: distance == null ? config.default_distance : distance
    };
    applyFilter(params);
  }

  const { searchLocation, searchDisplayLocation, distance } = searchParams;

  return (
    <LocationFacetContainer>
      <FormattedMessage {...messages.within} />
      <FormattedMessage {...messages.within_aria_label}>
        {(withinAriaLabelMessage) => (
          <select
            id="distance"
            className="ml-xs mr-xs"
            style={{ maxWidth: '100px' }}
            onChange={(event) =>
              setSearchParams({
                searchLocation,
                searchDisplayLocation,
                distance: event.target.value
              })
            }
            value={distance}
            aria-label={withinAriaLabelMessage}
          >
            {(config.distance_options || []).map((distance) => {
              let descriptor, values;
              if (distance === DISTANCE_ANY) {
                descriptor = messages.anymiles;
              } else {
                descriptor = messages.numericalmiles;
                values = { distance };
              }
              return (
                <IntlOption
                  value={distance}
                  key={distance}
                  messageDescriptor={descriptor}
                  messageDescriptorValues={values}
                />
              );
            })}
          </select>
        )}
      </FormattedMessage>
      <LocationInput
        searchableLocation={searchableLocation}
        displayLocation={searchDisplayLocation}
        suggestions={suggestions}
        onChange={(selectedItem) => {
          const params = {
            distance,
            searchLocation: selectedItem,
            searchDisplayLocation: selectedItem
          };
          setSearchParams(params);
          applyFilter(params);
        }}
        onInputValueChange={(inputValue) => {
          setSearchParams({
            distance,
            searchLocation: inputValue,
            searchDisplayLocation: inputValue
          });
          setInputSuggestionsTerm(inputValue);
        }}
        onSubmit={() => applyFilter(searchParams)}
        placeholder={messages.placeholder}
        /**
         * If CRM is enabled then the useCurrentLocation button causes a spinner of death because the application is iframed without geolocation access
         * If CRM is enabled or enable_use_current_location flag is not checked then return false otherwise true
         */
        enableUseCurrentLocation={
          !isModuleEnabled(customerConfig, MODULES.CRM_INTEGRATION) &&
          Boolean(config?.enable_use_current_location)
        }
        onUseCurrentLocation={handleUseCurrentLocation}
        distance={distance}
      />
    </LocationFacetContainer>
  );
}

export { BaseLocationFacet };
export default withRouter(BaseLocationFacet);
