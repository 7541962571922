import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from '@kyruus/intl';

import { SearchTypeahead } from '@kyruus/select';
import { makeInternational } from '../../utils/intl-components';
import { CurrentPositionBtn, ScreenReaderAriaLabel } from './styles';
import { useUserLocation, useCcfDemographicsLocation } from '../../hooks';

const IntlSearchTypeahead = makeInternational(SearchTypeahead);

const messages = defineMessages({
  useCurrentLocationLabel: {
    id: 'facet.location.user_current_location_label',
    description: 'Label to use on "use current location" cta',
    defaultMessage: 'Use current location'
  },
  locationInputAriaLabelText: {
    id: 'facet.location.aria_label_text',
    description: 'Aria label text for the location input',
    defaultMessage:
      'Type city, state, or zip to find providers within {distance} mile{distance, plural, one {} other {s}}'
  }
});

function mapStateToProps(state) {
  return {
    customerCode: state.customerCode,
    config: state.config,
    tokens: state.tokens
  };
}

const LocationInput = ({
  searchableLocation,
  displayLocation,
  suggestions,
  onChange,
  onInputValueChange,
  onSubmit,
  placeholder,
  enableUseCurrentLocation,
  onUseCurrentLocation,
  customerCode,
  config,
  tokens,
  distance
}) => {
  const [requestingBrowserLocation, setRequestingBrowserLocation] =
    useState(false);

  // gate behind the `requestingBrowserLocation` flag so that we only prompt the user
  // to allow giving us their location after they clicked on the CTA
  const { userLatLng, userHrfAddress, userLocationIsLoading } = useUserLocation(
    requestingBrowserLocation
  );

  // get patient zip from ccf if available
  const { patientZipSearchLocation, patientZip, patientZipPending } =
    useCcfDemographicsLocation({
      customerCode,
      config,
      bcsToken: tokens?.bcsToken
    });

  useEffect(() => {
    // when we get a lat/lon after requesting browser location, send it up to the callback
    if (requestingBrowserLocation) {
      if (userLatLng && userHrfAddress) {
        onUseCurrentLocation(userLatLng, userHrfAddress);
      }
    }
  }, [userLatLng, userHrfAddress, requestingBrowserLocation]);

  useEffect(() => {
    // when incoming displayLocation changes, that means a different search happened
    // and we're no longer using 'current' location. Resetting the state here allows
    // the user to 'use current location' multiple times in case they changed the query
    // from the original 'current location'
    setRequestingBrowserLocation(false);
  }, [displayLocation]);

  let locationInput;
  if (searchableLocation) {
    locationInput = (
      <React.Fragment>
        <div
          id="location-inputwrapper"
          style={{ position: 'relative', marginTop: '12px' }}
        >
          <ScreenReaderAriaLabel id="location-label">
            <FormattedMessage
              {...messages.locationInputAriaLabelText}
              values={{ distance: Number(distance) }}
            />
          </ScreenReaderAriaLabel>
          <IntlSearchTypeahead
            id="location"
            items={suggestions}
            onSubmit={(event) => {
              event.preventDefault();
              onSubmit();
            }}
            inputValue={displayLocation}
            truncate={true}
            onChange={onChange}
            onInputValueChange={onInputValueChange}
            intlProps={{ placeholder: { descriptor: placeholder } }}
          />
        </div>
        {enableUseCurrentLocation && (
          <CurrentPositionBtn
            data-testid="use-current-location"
            mode="flat"
            disabled={patientZipPending || userLocationIsLoading}
            showSpinner={patientZipPending || userLocationIsLoading}
            onClick={(event) => {
              event.preventDefault();
              // CCF patient zip
              if (patientZip) {
                onUseCurrentLocation(patientZipSearchLocation, patientZip, 0);
              } else {
                // browser geo
                setRequestingBrowserLocation(true);
              }
            }}
          >
            <FormattedMessage {...messages.useCurrentLocationLabel} />
          </CurrentPositionBtn>
        )}
      </React.Fragment>
    );
  } else {
    locationInput = (
      <div className="readonly-searchable-location-input">
        <h4 className="pt-s fc-gray-2 mb-s" id="display-location">
          {displayLocation}
        </h4>
        <button
          id="locationfilter-submit"
          className="location-inputbutton button btn-link btn-s kyruus-config-secondary-color"
          type="submit"
        >
          <span className="ky-icon-search icon-search fs-m" />
        </button>
      </div>
    );
  }

  return locationInput;
};

export default connect(mapStateToProps)(LocationInput);
