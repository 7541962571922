import React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from '@kyruus/intl';
import _clone from 'lodash/clone';
import _kebabCase from 'lodash/kebabCase';
import _map from 'lodash/map';
import _remove from 'lodash/remove';
import _slice from 'lodash/slice';
import querystring from 'querystring';
import {
  V8_PATIENT_REL_FILTER_KEY,
  V8_PURPOSE_FILTER_KEY
} from '../utils/constants';

const messages = defineMessages({
  showmore: {
    id: 'facet.showmore',
    description: 'Title for button to show more results',
    defaultMessage: 'Show More'
  },
  showless: {
    id: 'facet.showless',
    description: 'Title for button to show fewer results',
    defaultMessage: 'Show Less'
  }
});

function BookOnlineIcon() {
  return (
    <span
      className="icon-event-available fc-positive fs-l va-b mr-xs"
      id="book-online-facet-icon"
    />
  );
}

function isBookOnlineFacet(config) {
  return config.filter_param == 'direct_book_capable:T';
}

function isGlobalAvailabilityActive(query = '') {
  const params = querystring.parse(query.slice(1));
  const filter = Array.isArray(params.filter) ? params.filter : [params.filter];

  return (
    filter.filter((filter = '') => {
      return (
        filter.startsWith(V8_PATIENT_REL_FILTER_KEY) ||
        filter.startsWith(V8_PURPOSE_FILTER_KEY)
      );
    }).length === 2
  );
}

function Filter({ config, type, facetName, toggleFilter, searchSummary = {} }) {
  const id = 'facet-' + _kebabCase(config.value) + '-' + _kebabCase(facetName);
  const dataTestId = `facet-${_kebabCase(config.value)}-${_kebabCase(
    facetName
  )}`;
  const is_book_online = isBookOnlineFacet(config);
  const filterLabelMessageDescriptor = {
    id: `field.value.${facetName}.${config.value}`,
    defaultMessage: config.value,
    description: 'The label for the filter'
  };
  return (
    <li>
      <label htmlFor={id} className="pr-s" id={id + '-label'}>
        <input
          id={id}
          data-testid={dataTestId}
          type={type}
          onClick={() => toggleFilter(config.filter_param)}
          defaultChecked={config.applied}
          name={facetName}
          className="mr-xs"
          disabled={
            is_book_online &&
            isGlobalAvailabilityActive(searchSummary.query_string)
          }
        />
        {is_book_online ? <BookOnlineIcon /> : null}
        <FormattedMessage {...filterLabelMessageDescriptor} />
        {config.count ? <span className="count"> ({config.count})</span> : null}
      </label>
    </li>
  );
}

class BasicFacet extends React.Component {
  constructor(props) {
    super(props);
    this.toggleShowMore = this.toggleShowMore.bind(this);
    this.state = { hide_terms: true };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.config != this.props.config) {
      this.setState({ hide_terms: true });
    }
  }

  toggleShowMore() {
    this.setState({ hide_terms: !this.state.hide_terms });
  }

  render() {
    const config = this.props.config;
    const maxVisible = config.max_visible || 5;
    const facetName = this.props.facetName;
    const searchSummary = this.props.searchSummary;
    const intl = this.props.intl;

    const terms = _clone(config.terms);
    const appliedTerms = _remove(terms, 'applied');
    const appliedFilters = _map(appliedTerms, (filter) => {
      return (
        <Filter
          type={config.type}
          config={filter}
          facetName={facetName}
          toggleFilter={this.props.removeFilter}
          key={filter.value}
          searchSummary={searchSummary}
        />
      );
    });
    const to_show = this.state.hide_terms
      ? Math.max(maxVisible - appliedTerms.length, 0)
      : terms.length;
    const unappliedTerms = _slice(terms, 0, to_show);
    const unappliedFilters = _map(unappliedTerms, (filter) => {
      return (
        <Filter
          type={config.type}
          config={filter}
          facetName={facetName}
          toggleFilter={this.props.addFilter}
          key={filter.value}
          searchSummary={searchSummary}
        />
      );
    });

    let showMoreLink;
    if (config.terms.length > maxVisible && terms.length > 0) {
      let showMoreLessText;
      if (this.state.hide_terms) {
        showMoreLessText = <FormattedMessage {...messages.showmore} />;
      } else {
        showMoreLessText = <FormattedMessage {...messages.showless} />;
      }
      showMoreLink = (
        <div className="show-more">
          <button
            id={_kebabCase(facetName) + '-facet-show-hide'}
            data-testid={_kebabCase(facetName) + '-facet-show-hide'}
            className="button btn-anchor"
            onClick={this.toggleShowMore}
            aria-label={`${intl.formatMessage(
              this.state.hide_terms ? messages.showmore : messages.showless
            )} ${intl.formatMessage(this.props.labelName)}`}
            type="button"
          >
            {showMoreLessText}
          </button>
        </div>
      );
    }

    return (
      <div className="filter-box multi">
        <ul
          data-testid={`filter-box-${_kebabCase(facetName)}`}
          className="no-bullets"
          id={'facet-group-' + _kebabCase(facetName)}
        >
          {appliedFilters}
          {unappliedFilters}
        </ul>
        {showMoreLink}
      </div>
    );
  }
}

export { isBookOnlineFacet };
export default injectIntl(BasicFacet);
